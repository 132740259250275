<template>
	<v-sheet>
		<h2 class="font-weight-light text-h4">Welcome</h2>
		<p class="text-h6 mb-5 mt-5">
			Welcome to the Scottish Government/COSLA Health and Social Care Digital
			Maturity Self-Assessment stakeholder dashboard.
		</p>
		<p style="line-height: 1.8em">
			This website is a tool for assessment stakeholders and provides
			information on assessment progress, data quality and preliminary results
			along with access to the assessment dataset.
		</p>
		<p style="line-height: 1.8em">
			If you have been provided with login details, please enter them using the
			login form accessible via the “Login” button at the top left of the
			screen. If you have any questions or experience problems with logging in,
			please contact support. For more information on how to complete the
			survey, please visit the tutorial page after logging in.
		</p>
		<p style="line-height: 1.8em">
			Please note that this website is intended for intended for commissioning
			stakeholders only and is not accessible to the public. If you have any
			general questions or comments, please contact hscdigitalmaturity@gov.scot,
			we will be happy to answer your queries or put you in touch with a member
			of staff who can assist you.
		</p>
	</v-sheet>
</template>

<script setup></script>
