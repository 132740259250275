<template>
	<v-navigation-drawer
		touchless
		permanent
		v-model="drawer"
		:mini-variant="mini">
		<v-list>
			<welcome-message></welcome-message>
		</v-list>
		<v-list dense>
			<slot></slot>
			<template v-for="(item, i) in items" :key="i">
				<v-list-item router-link :to="item.link">
					<template v-slot:prepend>
						<v-icon>
							{{ item.icon }}
						</v-icon>
					</template>
					<v-list-item-title>
						{{ item.title }}
					</v-list-item-title>
				</v-list-item>
			</template>
			<template v-if="auth.id">
				<dashboard-navigation></dashboard-navigation>
				<template v-for="(item, i) in logged_in_items" :key="i">
					<v-list-item router-link :to="item.link">
						<template v-slot:prepend>
							<v-icon>
								{{ item.icon }}
							</v-icon>
						</template>
						<v-list-item-title>
							{{ item.title }}
						</v-list-item-title>
					</v-list-item>
				</template>
			</template>
		</v-list>
		<template v-slot:append>
			<v-list>
				<template v-for="(item, i) in append" :key="i">
					<v-list-item router-link :to="item.link">
						<v-list-item-title>
							{{ item.title }}
						</v-list-item-title>
						<template v-slot:append>
							<v-icon>
								{{ item.icon }}
							</v-icon>
						</template>
					</v-list-item>
				</template>
				<log-out v-if="auth.id"></log-out>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<style>
	.v-list-group__items .v-list-item {
		-webkit-padding-start: 32px !important;
		padding-inline-start: 32px !important;
	}
</style>
<script setup>
	import {ref} from "vue";
	import {useAuthStore} from "@/stores/auth";
	import LogOut from "@c/auth/LogOut";
	import WelcomeMessage from "@c/auth/WelcomeMessage";
	import DashboardNavigation from "@c/navigation/DashboardNavigation";
	// import NavItem from '@c/navigation/Item';
	const auth = useAuthStore();
	let mini = ref(false);
	let drawer = ref(true);

	let items = [];

	let append = [{icon: "mdi-email", title: "Contact Us", link: "/contact-us"}];
	let logged_in_items = ref([
		{
			icon: "mdi-hospital-building",
			title: "Find Organisation",
			link: "/find-organisation"
		},
		{icon: "mdi-download", title: "Request Data", link: "/request-data"}
	]);
</script>
