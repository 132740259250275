<template>
	<v-list-item>
		<template v-slot:prepend>
			<v-icon>mdi-account</v-icon>
		</template>
		<v-btn v-if="!auth.isLoggedIn" block color="success" @click="dialog = true">
			Log In
		</v-btn>
		<v-list-item-title  v-else>
			<span> Welcome {{ auth.name }} </span>
		</v-list-item-title>		
			<log-in v-model="dialog"></log-in>
	</v-list-item>
		
</template>

<script async setup>
	import {ref} from "vue";
	import {useAuthStore} from "@/stores/auth";
	import LogIn from "@c/auth/LogIn";
	const auth = useAuthStore();
	const dialog = ref(false);
</script>
