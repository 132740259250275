import _ from "lodash";

import {getAuth, signOut} from "firebase/auth";
import {getCurrentUser} from "vuefire";

import router from "@/router";

const mw = ({store}) => {
	if (!store.options) {
		store.options = {
			type: null,
			collectionName: null,
			paginate: false,
			loadAll: false,
			query: false,
			limit: null,
			orderBy: null
		};
	}
	// store.status =  null;
	// store.error = null;
	// store.ref = null;

	if (process.env.NODE_ENV === "development") {
		store._customProperties.add("options").add("status").add("error");
	}
};

const mwAuth = ({store}) => {
	if (store.$id == "auth") {
		store.login = async (userObj) => {
			if (!userObj) {
				userObj = await getCurrentUser();
			}
			if (!userObj) {
				return;
			}
			store.watchDoc(userObj.uid);
		};
		store.logout = (path) => {
			signOut(getAuth()).then(() => {
				router.replace(path);
				store.unwatchDoc();
				store.$reset();
			});
		};
	}
};

const mwReset = ({store}) => {
	const initialState = _.cloneDeep(store.$state);
	store.$reset = () => store.$patch(_.cloneDeep(initialState));
};

export {mw, mwAuth, mwReset};
