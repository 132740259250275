<template>
	<v-list>
		<v-list-group>
			<template v-slot:activator="{props}">
				<v-list-item
					v-bind="props"
					prepend-icon="mdi-star"
					title="Dashboard"></v-list-item>
			</template>
			<v-list-item
				prepend-icon="mdi-home"
				title="Home"
				router-link
				to="/dashboard">
			</v-list-item>
			<v-list-group>
				<template v-slot:activator="{props}">
					<v-list-item
						v-bind="props"
						title="Progress"
						prepend-icon="mdi-progress-check"></v-list-item>
				</template>
				<v-list-item class="pl-4"
					title="By Organisation"
					router-link
					to="/dashboard/organisation-progress"></v-list-item>
				<v-list-item  class="pl-4"
					title="By Section"
					router-link
					to="/dashboard/section-progress"></v-list-item>
			</v-list-group>
			<v-list-group>
				<template v-slot:activator="{props}">
					<v-list-item
						v-bind="props"
						title="Scores"
						prepend-icon="mdi-percent"></v-list-item>
				</template>
				<v-list-item
					title="By Organisation"
					router-link
					to="/dashboard/organisation-scores"></v-list-item>
				<v-list-item
					title="By Section"
					router-link
					to="/dashboard/section-scores"></v-list-item>
			</v-list-group>
			<v-list-item
				prepend-icon="mdi-comment-account"
				title="Staff Survey"
				to="/dashboard/staff-survey"
				router-link>
			</v-list-item>
			<v-list-item
				prepend-icon="mdi-account-group"
				title="Team Size"
				to="/dashboard/by-team-size"
				router-link>
			</v-list-item>
			<v-list-item
				prepend-icon="mdi-account-group"
				title="Collaborations"
				to="/dashboard/by-collaborations"
				router-link>
			</v-list-item>

			
		</v-list-group>
	</v-list>
</template>
<style lang="less">
</style>
<script setup></script>
