import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './stores'

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
loadFonts()
import {firebaseApp} from "../mwFirebase";
import { VueFire, VueFireAuth } from 'vuefire'
import ganttastic from '@infectoone/vue-ganttastic'


const app = createApp(App);

app.use(router)
.use(ganttastic)
.use(store)
.use(vuetify)
.use(VueFire, {
  firebaseApp: firebaseApp,
  modules: [
    // ... other modules
    VueFireAuth(),
  ],
}).mount('#app')



export { app, firebaseApp }