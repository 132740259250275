<template>
    <v-list-item @click="auth.logout">
        <template v-slot:append>
            <v-icon>mdi-logout-variant</v-icon>
        </template>
        <v-list-item-title>Log out</v-list-item-title>
    </v-list-item>
</template>
<script setup>
import { useAuthStore } from '@/stores/auth';

const auth = useAuthStore();
    import { useOrganisationsStore } from "@/stores/organisations";
    const organisationsStore = useOrganisationsStore();
    organisationsStore;

</script>