<template>
	<v-snackbar
        variant="tonal"
        close-on-content-click
		:model-value="value"
		@update:modelValue="toggle"
		:color="toast.error ? 'error' : 'primary'">
		{{ toast.message }}
	</v-snackbar>
</template>

<script setup>
	import {computed} from "vue";
	import {useToastStore} from "@/stores/toast";
	let toast = useToastStore();
	let value = computed(() => {
		return toast.on;
	});
	const toggle = (v) => {
        if( !v ){
            toast.hide();
        }
	};
</script>
