import {defineStore} from "pinia";
import {ref} from "vue";

export const useStaffSurveyResponsesStore = defineStore("staffSurveyResponses", () => {
	// settings for the collection plugin
	const type = "collection";
	const collectionName = "staffSurveyResponses";
	const sectionId =  "F4ayWSwHXBl8X1QXbXK4";
	let where = ref([
		["section", "==", sectionId]
	]);

	return {
		where,
		sectionId,
		options: ref({
			type,
			collectionName,
			orderBy: "created_at",
			paginate: true,
			limit: 5
		})
	};
});
