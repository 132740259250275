import {createRouter, createWebHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView
	},
	{
		path: "/error",
		name: "error",
		props: true,
		component: () =>
			import(/* webpackChunkName: "error" */ "../views/ErrorView.vue")
	},
	{
		path: "/find-organisation",
		name: "find-organisation",
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "find-organisation" */ "../views/FindOrganisationView.vue"
			)
	},
	{
		path: "/contact-us",
		name: "contactUs",
		props: true,
		component: () =>
			import(/* webpackChunkName: "contactUs" */ "../views/ContactUsView.vue")
	},
	{
		path: "/request-data",
		name: "requestData",
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "requestData" */ "../views/RequestDataView.vue"
			)
	},
	{
		path: "/dashboard/staff-survey",
		name: "staffSurvey",
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "staffSurvey" */ "../views/StaffSurveyView.vue"
			)
	},
	{
		path: "/dashboard/:view",
		name: "dashboardWithView",
		props: true,
		component: () =>
			import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue")
	},
	{
		path: "/dashboard",
		name: "dashboard",
		component: () =>
			import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue")
	},
	{
		path: "/organisation/:id/:view",
		name: "organisation",
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "organisation" */ "../views/OrganisationView.vue"
			)
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
