import {defineStore} from "pinia";
import { ref, computed } from "vue";

export const useAuthStore = defineStore('auth', () => {
	const collectionName = "users";
	const type = "doc";
	
	let id = ref(null);
	let email = ref(null);
	let phone = ref(null);
	let name = ref(null);
	let last_login = ref(null);
	let created_at = ref(null);
	let role = ref("user");
	let organisations = ref([]);
	let registration_type = ref("user");
	let created_by = ref(null);
	let status = ref(null);
	let approver = ref(null);
	let updated_at = ref(null);
	let registration_method = ref(null);
	let organisation_stakeholder = ref(null);
	let updated_by = ref(null);
	let universal_stakeholder = ref(null);
	let isLoggedIn = computed(() => {
		return id.value ? true : false
	})
	let accessDenied = computed(() => {
		if( !id.value ){
			return null;
		}
		return !role.value == "admin" || ( !organisation_stakeholder.value && !universal_stakeholder.value );
	})

	let emptyDoc = () => {
		// logout({name: "error", props: {error: "You do not have permission to access this site"}})
	}		
	return {
		id,
		options: {
			collectionName,
			type
		},
		emptyDoc,
		name,
		email,
		phone,
		last_login,
		created_at,
		role,
		organisations,
		registration_type,
		created_by,
		status,
		approver,
		updated_at,
		registration_method,
		organisation_stakeholder,
		updated_by,
		universal_stakeholder,
		isLoggedIn,
		accessDenied
	}
})