import { ref } from "vue";
import {defineStore} from "pinia";
export const useToastStore = defineStore("toasts", () => {

    let error = ref(false);
    let on = ref(false);
    let message = ref(null);

    const show = (m, e) => {
        message.value = m;
        error.value = e;
        on.value = true;
    }

    const hide = () => {
        on.value = false;
        message.value = null;
    }
	return {
        error, on, message, show, hide
	};
});
 