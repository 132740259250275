import {defineStore} from "pinia";
import {ref} from "vue";
import {useStaffSurveyResponsesStore} from "./staffSurveyResponses";
import {query, where, getCountFromServer} from "firebase/firestore";
export const useOrganisationsStore = defineStore("organisations", () => {
	// settings for the collection plugin
	const type = "collection";
	const collectionName = "organisations";
	const loadAll = true;

	const getStaffSurveyCount = async (id) => {
		const staffSurveyResponsesStore = useStaffSurveyResponsesStore();
		let q = staffSurveyResponsesStore.query;
		q = query(staffSurveyResponsesStore.ref, where("organisation", "==", id));
		const snapshot = await getCountFromServer(q);
		return snapshot.data().count;
	};

	return {
		options: {
			type,
			collectionName,
			loadAll
		},
		type,
		where: ref([["status", "==", "published"]]),
		collectionName,
		getStaffSurveyCount
	};
});
