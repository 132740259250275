<template>
	<v-app>
		<v-main>
			<v-sheet>
				<side-navigation></side-navigation>
				<v-sheet class="pa-8">
					<router-view />
				</v-sheet>
				<toast-notice></toast-notice>
			</v-sheet>
		</v-main>
	</v-app>
</template>

<style lang="less">
	.vue3-easy-data-table__main tr {
		cursor: pointer;
	}
</style>

<script setup>
	import SideNavigation from "@c/navigation/SideNavigation";
	import {computed, watch, onMounted} from "vue-demi";
	import {useAuthStore} from "./stores/auth";
	import ToastNotice from "@c/ui/ToastNotice";

	const auth = useAuthStore();
	auth.login();
	let accessDenied = computed(() => {
		return auth.accessDenied;
	});
	watch(
		accessDenied,
		(value) => {
			if (value == true) {
				auth.logout("error");
			}
		},
		{deep: true}
	);

	onMounted(( ) => {
		document.title = "SG DMA Dashboard"
	})
</script>
