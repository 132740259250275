import {createPinia} from "pinia";
import {mw,  mwAuth, mwReset} from "./plugins/mwPlugins";
import mwUseCollection  from "./plugins/mwCollection";
import mwUseDoc  from "./plugins/mwDoc";

const store = createPinia();
store.use(mwUseDoc)
.use(mw)
.use(mwUseCollection)
.use(mwAuth)
.use(mwReset)
export default store;
