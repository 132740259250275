<template>
	<v-dialog :value="value" width="360" title="Log In" v-if="!auth.id" eager>
		<v-card>
			<div id="ig-firebaseui-auth-container"></div>
		</v-card>
	</v-dialog>
</template>
<style lang="less">
@import "../../../node_modules/firebaseui/dist/firebaseui.css";

#ig-firebaseui-auth-container {
	background-color: white;
	.firebaseui-page-provider-sign-in {
		padding: 30px 0px;
	}
}
.firebaseui-id-submit,
.firebaseui-button,
.mdl-button--raised.mdl-button--colored {
	background: black !important;
	color: white !important;
}
</style>
<script setup>
	import { onMounted, defineEmits, defineProps } from 'vue';
	import { getAuth, EmailAuthProvider  } from "firebase/auth";
	import * as firebaseui from "firebaseui";
	import { useAuthStore } from '@/stores/auth';
	const $emit = defineEmits(["input"]);
	defineProps(["value"]);
	const auth = useAuthStore();
	const path = window.location.href;
	onMounted(() => {
		var uiConfig = {
			callbacks: {
				signInSuccessWithAuthResult: function(authResult) {
					if( authResult ){
						$emit("input", false)
						auth.login(authResult.user)
						
						// .then( () => auth.redirectToDashboard() )
					}
				},
			},
			signInOptions: [
				{
					provider: EmailAuthProvider.PROVIDER_ID,
					signInMethod:
						EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
					emailLinkSignIn: function() {
						return {
							url: path,
						};
					},
				},
			],
		};
		var ui =
			firebaseui.auth.AuthUI.getInstance() ||
			new firebaseui.auth.AuthUI(getAuth());
		ui.start("#ig-firebaseui-auth-container", uiConfig);
	})

</script>
